<template>
  <div class="table-responsive">
    <b-table
        id="roles-table"
        class="table-vertical-center"
        show-empty
        :items="items"
        :fields="fields">
      <template #empty="scope">
        <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
      </template>
      <template #head()="data">
        <span class="pl-0 text-muted font-weight-lighter">{{ data.label }}</span>
      </template>
      <template #head(actions)="data">
        <span class="pl-0 text-muted font-weight-lighter float-right">{{ data.label }}</span>
      </template>
      <template #cell(name)="data">
        <p class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg mt-2 mb-0">{{ data.item.name }}</p>
        <span style="max-width: 350px;" class="d-block font-weight-lighter font-size-md">{{ data.item.description }}</span>
      </template>
      <template #cell(type)="data">
        <span class="label label-lg label-inline m-1">{{ data.item.type === 'default' ? $t("MANAGEMENT.DEFAULT_LABEL") : '' }}</span>
      </template>
      <template #cell(actions)="data">
          <span class="float-right">
<!--            <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm"-->
<!--                      v-b-modal.modal-1-->
<!--                      v-b-tooltip.hover.v-dark="'Настроить'">-->
<!--              <Settings/>-->
<!--            </b-button>-->
            <b-button v-if="data.item.type !== 'default'"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      v-b-modal.modal-1
                      v-b-tooltip.hover.v-dark="$t('MANAGEMENT.DELETE_TOOLTIP_TEXT')">
              <Trash/>
            </b-button>
          </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import Settings from "@/view/layout/svg/Settings";
import Trash from "@/view/layout/svg/Trash";

export default {
  name: "Table",
  components: {
    Settings,
    Trash
  },
  props: {
    table: String,
    items: {
      type: Array
    },
    fields: {
      type: Array
    }
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>

</style>
