<template>
  <!--begin::Roles-->
  <div v-if="isCan" class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.ROLES") }}
          <span style="max-width: 350px;"
                class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.ROLES_DESC") }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
<!--        <b-button v-b-modal.modal-1-->
<!--                  class="btn bg-light-primary font-weight-bolder font-size-sm">-->
<!--          <i class="fas fa-plus icon-md"></i>Добавить роль-->
<!--        </b-button>-->
        <!--end::Button-->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Search-->
      <div class="row align-items-center mb-7">
        <div class="col-lg-10 col-xl-10">
          <div class="row align-items-center">
            <div class="col-md-4 my-2 my-md-0">
              <div class="input-icon">
                <input type="text" class="form-control" v-model="filter_name" :placeholder="$t('SELECT.SEARCH')" />
                <span><i class="flaticon-search text-muted"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Search-->
      <!--begin::Table-->
      <Table
          :table="'roles'"
          :items="rolesList"
          :fields="fields"/>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--begin::Modal-->
    <b-modal id="modal-1" title="Title">
      <p class="my-4">Hello from modal!</p>
    </b-modal>
    <!--end::Modal-->
  </div>
  <!--end::Roles-->
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ROLES_LIST } from "@/core/services/store/management.module";

import Table from "@/view/layout/table/Table";

export default {
  name: "Roles",
  components: {
    Table
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MANAGEMENT.ROLES") }]);
    this.$store.dispatch(GET_ROLES_LIST);
  },
  data() {
    return {
      filter_name: '',
      fields: [
        { key: 'name', label: this.$t("MANAGEMENT.ROLE_TABLE_TITLE") },
        { key: 'type', label: this.$t("MANAGEMENT.TYPE_TABLE_TITLE") },
        // { key: 'actions', label: 'Действия' }
      ]
    }
  },
  watch: {
    filter_name(val) {
      this.$store.dispatch(GET_ROLES_LIST, val);
    }
  },
  computed: {
    ...mapGetters([
      "rolesList"
    ]),
    isCan() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[2]));
        return role !== undefined;
      }
    }
  }
}
</script>

<style scoped>

</style>
